import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressProvider from "./ProgressProvider";
import CountUp from 'react-countup';

const CircularProgressbar = ( { percentage, goal, done, circleAnimationDuration, countAnimationDuration } ) => {

  return <div className="statisticCircle">
    <ProgressProvider valueStart={0} valueEnd={percentage}>
      {value => <CircularProgressbarWithChildren
        value={value}
        background={true}
        strokeWidth={3}
        styles={buildStyles( {
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 1,

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',

          pathTransitionDuration: circleAnimationDuration,

          // Colors
          pathColor: `rgba(30, 144, 255, 1)`,
          textColor: 'rgba(30,144,255,1)',
          trailColor: '#d6d6d6',
          backgroundColor: '#ffffff',
        } )}
      >

        <div>Ziel: {goal}km</div>
        <div>Erreicht: {done}km</div>
        <div className="font48BoldDodgerBlue">
          <CountUp
            start={0}
            end={percentage}
            duration={countAnimationDuration}
            separator=" "
            suffix=" %"
            delay={0}
          />
        </div>
      </CircularProgressbarWithChildren>}
    </ProgressProvider>
  </div>
}

export default CircularProgressbar




