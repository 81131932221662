import React from 'react';
import Start from "./Start";


const Error = () => {
  return <Start error={true}/>;
}

export default Error;


