import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import he from 'he';
import useCheckToken from "./CheckToken";
import { VIDEO_CALLER } from "./store/dataReducer";
import Header from "./Header";
import RestClient from "./RestClient";


const getVideoInfos = ( token, id, setVideoData, history ) => {

  const url = "https://svebar.de/new/space/video.php";
  const body = "token=" + token + "&id=" + id;

  const onSuccess = ( data ) => setVideoData( data );

  RestClient( url, body, onSuccess , history );
}


const Video = () => {

  const [validUser, setValidUser] = useState( false );
  useCheckToken( validUser, setValidUser );

  const { id } = useParams();
  const history = useHistory();
  const user = useSelector( state => state.user );
  const videoCaller = useSelector( state => state.data.videoCaller );
  const [videoData, setVideoData] = useState( null );
  const [goBackToCaller, setGoBackToCaller] = useState( false );


  useEffect( () => {
    if( validUser && user && user.token !== null && (!videoData || videoData === null) ) {
      getVideoInfos( user.token, id, setVideoData, history );
    }
  }, [user, id, videoData, validUser, setVideoData, history] );


  if( goBackToCaller === true ) {
    setGoBackToCaller( false );

    if( videoCaller === null || videoCaller === VIDEO_CALLER.VIDEOS ) {
      history.push( "/videos/".concat( user.token ) );
    }
    else if( videoCaller === VIDEO_CALLER.OVERVIEW ) {
      history.push( "/overview/".concat( user.token ) );
    }
    return null;
  }


  if( !validUser ) return null;


  if( !videoData || videoData === null ) {
    return <div className="lds-dual-ring"></div>
  }


  return <>

    <Header user={user}/>

    <div className="font48DodgerBlue pointer marginLeft20"
         title="Zurück"
         onClick={() => setGoBackToCaller( true )}>&larr;</div>

    <div className="marginBottom80" style={{
      "margin-left": "auto",
      "margin-right": "auto",
      "width": "80%"
    }}>


      <div className=" font32DodgerBlue ">
        {he.decode( videoData.description )}
      </div>

      <div className="marginTop40">
        <video width="100%" controls>
          <source src={"https://svebar.de/space/videos/".concat( videoData.fileName )} type="video/mp4"/>
          Ein Fehler ist aufgetretetn. Dein Browser unterst&uuml;tzt keine HTML5 Videos!
        </video>
      </div>

    </div>
  </>


}

export default Video;


