export const SET_OVERVIEW_DATA = "SET_OVERVIEW_DATA";
export const SET_IMAGE_GALLERIES = "SET_IMAGE_GALLERIES";
export const SET_RANDOM_GALLERY_DATA = "SET_GALLERY_DATA";
export const SET_GALLERY_CALLER = "SET_GALLERY_CALLER";
export const APPEND_MORE_NEWS = "APPEND_MORE_NEWS";
export const SET_VIDEOS = "SET_VIDEOS";
export const SET_VIDEO_CALLER = "SET_VIDEO_CALLER";

export const GALLERY_CALLER = {
  OVERVIEW: "overview",
  GALLERIES: "galleries"
}

export const VIDEO_CALLER = {
  OVERVIEW: "overview",
  VIDEOS: "videos"
}

const initialState = {
  galleryCount: null,
  videoCount: null,
  news: [],
  imageGalleries: [],
  randomGalleryData: [],
  videos: [],
  galleryCaller: null,
  videoCaller: null,
  initialized: false,
  offset: 3
}

const dataReducer = ( state = initialState, action ) => {

  switch( action.type ) {
    case SET_OVERVIEW_DATA: {
      let newState = {
        ...state,
        galleryCount: action.data.galleryCount,
        videoCount: action.data.videoCount,
        news: action.data.news,
        initialized: true
      };
      return newState;
    }
    case SET_IMAGE_GALLERIES: {
      let newState = {
        ...state,
        imageGalleries: action.data.galleries
      };
      return newState;
    }
    case SET_RANDOM_GALLERY_DATA: {
      let newState = {
        ...state,
        randomGalleryData: action.data
      };
      return newState;
    }
    case SET_GALLERY_CALLER: {
      let newState = {
        ...state,
        galleryCaller: action.caller
      };
      return newState;
    }
    case APPEND_MORE_NEWS: {
      const newNews = [];
      state.news.forEach( newsEntry => newNews.push( newsEntry ) );
      action.news.forEach( newsEntry => newNews.push( newsEntry ) );

      const newOffset = state.offset + 3;
      let newState = {
        ...state,
        news: newNews,
        offset: newOffset
      }
      return newState;
    }
    case SET_VIDEOS: {
      let newState = {
        ...state,
        videos: action.data.videos
      };
      return newState;
    }
    case SET_VIDEO_CALLER: {
      let newState = {
        ...state,
        videoCaller: action.caller
      };
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default dataReducer;