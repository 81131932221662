import React from 'react';
import LoginForm from "./LoginForm";
import Header from "./Header";


const Start = ( { invalidSession = false, error = false, logout = false } ) => {
  return <>

    <Header user={null}/>

    <div className="center800 marginTop40">

      <div className="marginTop40  marginLeft20 font48LightBlue">
        Willkommen
      </div>

      {logout && <div>Du wurdest erfolgreich abgemeldet.</div>}
      {invalidSession && <div>Deine Sitzung ist abgelaufen. Bitte melde Dich erneut an.</div>}
      {error && <div>Entschuldigung, ein Fehler ist aufgetreten! Bitte versuche Dich erneut anzumelden.</div>}

      <div className="marginTop40 font32Black  marginLeft20">
        <LoginForm/>
      </div>

      <div className="marginTop40 font32Black  marginLeft20 marginBottom80">
        <hr/>

      </div>
    </div>
  </>
}

export default Start




