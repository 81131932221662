export const USER_LOGIN = "USER_LOGIN";

const initialState = {
  name: null,
  token: null
}

const userReducer = ( state = initialState, action ) => {

  switch( action.type ) {
    case USER_LOGIN: {
      state = {
        ...state,
        name: action.user.name,
        token: action.user.token
      };
      return state;
    }
    default: {
      return state;
    }
  }
};

export default userReducer;