import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { Bar } from 'react-chartjs-2';
import CircularProgressbar from "./CircularProgressbar";
import { useHistory } from "react-router-dom";
import useCheckToken from "./CheckToken";
import { useSelector } from "react-redux";
import Header from "./Header";


const data2021 = {
  goal: 4000,
  done: 3547,
  percentageRounded: 88.67,
  percentage: "88,675",
  labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  datasets: [{
    label: 'Gefahrene Kilometer',
    backgroundColor: 'rgba(30,144,255,1)',
    borderColor: 'rgba(0,0,0,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(65,105,255,1)',
    hoverBorderColor: 'rgba(0,0,0,1)',
    data: [0, 133, 293, 255, 129, 216, 325, 451, 517, 562, 462, 204]
  }]
};


const data2020 = {
  goal: 4000,
  done: 3174,
  percentageRounded: 79.35,
  percentage: "61,875",
  labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  datasets: [{
    label: 'Gefahrene Kilometer',
    backgroundColor: 'rgba(30,144,255,1)',
    borderColor: 'rgba(0,0,0,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(65,105,255,1)',
    hoverBorderColor: 'rgba(0,0,0,1)',
    data: [371, 197, 284, 232, 331, 252, 533, 275, 480, 168, 51, 0]
  }]
};

const data2019 = {
  goal: 3000,
  done: 4122,
  percentageRounded: 137,
  percentage: "137,4",
  labels: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  datasets: [{
    label: 'Gefahrene Kilometer',
    backgroundColor: 'rgba(30,144,255,1)',
    borderColor: 'rgba(0,0,0,1)',
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(65,105,255,1)',
    hoverBorderColor: 'rgba(0,0,0,1)',
    data: [0, 257, 241, 243, 269, 483, 811, 546, 589, 360, 236, 87]
  }]
};


const BikeStatistic = () => {

  const [validUser, setValidUser] = useState( false );
  useCheckToken( validUser, setValidUser );

  const user = useSelector( state => state.user );
  const history = useHistory();
  const [goToOverview, setGoToOverview] = useState( false );

  if( goToOverview === true ) {
    setGoToOverview( false );
    history.push( "/overview/".concat( user.token ) );
    return null;
  }

  if( !validUser ) return null;

  return <>

    <Header user={user}/>

    <div className="font48DodgerBlue pointer marginLeft20"
         title="Zurück zur Startseite"
         onClick={() => setGoToOverview( true )}>&larr;</div>

    <div className="center800 ">

      <div className="marginTop40 font48LightBlue marginLeft20 ">
        <img style={{ "vertical-align": "middle" }}
             src="https://svebar.de/new/images/bike_blue.png"
             alt="bike"/> Statistik
      </div>


      <div className="marginTop40 floatLeft marginLeft20 marginRight20">
        <div className="font48LightBlue centeredText100PercentWidth">
          2021
        </div>
        <div className="marginTop40 ">
          <CircularProgressbar percentage={data2021.percentageRounded} goal={data2021.goal} done={data2021.done} circleAnimationDuration={3} countAnimationDuration={3}/>
        </div>
      </div>

      <div className="marginTop40 floatLeft marginLeft20 marginRight20">
        <div className="font48LightBlue centeredText100PercentWidth">
          2020
        </div>
        <div className="marginTop40 ">
          <CircularProgressbar percentage={data2020.percentageRounded} goal={data2020.goal} done={data2020.done} circleAnimationDuration={3} countAnimationDuration={3}/>
        </div>
      </div>

      <div className="marginTop40 floatLeft marginLeft20 ">
        <div className="font48LightBlue centeredText100PercentWidth">
          2019
        </div>
        <div className="marginTop40 ">
          <CircularProgressbar percentage={data2019.percentageRounded} goal={data2019.goal} done={data2019.done} circleAnimationDuration={3} countAnimationDuration={4}/>
        </div>
      </div>

      <div style={{ clear: "both" }}></div>


      <div className="marginTop40 font48LightBlue centeredText100PercentWidth">
        2021
      </div>
      <div className="marginTop20 font18BoldBlack centeredText100PercentWidth">
        Ziel: {data2021.goal} km<br/>
        Erreicht: {data2021.done} km<br/>
        {data2021.percentage} %
      </div>
      <div className="statisticBarChart marginTop40">
        <Bar
          data={data2021}
          options={{
            maintainAspectRatio: true
          }}
        />
      </div>


      <div className="marginTop40 font48LightBlue centeredText100PercentWidth">
        2020
      </div>
      <div className="marginTop20 font18BoldBlack centeredText100PercentWidth">
        Ziel: {data2020.goal} km<br/>
        Erreicht: {data2020.done} km<br/>
        {data2020.percentage} %
      </div>
      <div className="statisticBarChart marginTop40">
        <Bar
          data={data2020}
          options={{
            maintainAspectRatio: true
          }}
        />
      </div>


      <div className="marginTop40 font48LightBlue centeredText100PercentWidth">
        2019
      </div>
      <div className="marginTop20 font18BoldBlack centeredText100PercentWidth">
        Ziel: {data2019.goal} km<br/>
        Erreicht: {data2019.done} km<br/>
        {data2019.percentage} %
      </div>
      <div className="statisticBarChart marginTop40 marginBottom80">
        <Bar
          data={data2019}
          options={{
            maintainAspectRatio: true
          }}
        />
      </div>
    </div>
  </>


}

export default BikeStatistic




