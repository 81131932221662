import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import RestClient from "./RestClient";


const logout = ( token, history ) => {
  const url = "https://svebar.de/new/space/logmeout.php";
  const body = "token=" + token;

  const onSuccess = ( data ) => history.push( "/logout" );

  RestClient( url, body, onSuccess, history );
}

const Header = ( { user } ) => {

  const location = useLocation();
  const history = useHistory();
  const [goToOverview, setGoToOverview] = useState( false );
  const [goToGalleries, setGoToGalleries] = useState( false );
  const [goToVideos, setGoToVideos] = useState( false );
  const [goToStatistic, setGoToStatistic] = useState( false );

  const toggleDropdown = ( event ) => {
    event.stopPropagation();
    document.getElementById( 'dropIcon' ).classList.toggle( "change" );
    document.getElementById( "myDropdown" ).classList.toggle( "show" );
  }

  useEffect( () => {
    // Close the dropdown menu if the user clicks outside of it
    window.addEventListener( "click", function( event ) {

      const dropdownElement = document.getElementById( "myDropdown" );
      if( dropdownElement && dropdownElement.classList.contains( "show" ) ) {
        if( event.target.id !== "dropIcon" ) {
          toggleDropdown( event );
        }
      }
    } )
  }, [] );


  if( goToOverview === true ) {
    setGoToOverview( false );
    history.push( "/overview/".concat( user.token ) );
    return null;
  }

  if( goToGalleries === true ) {
    setGoToGalleries( false );
    history.push( "/galleries/".concat( user.token ) );
    return null;
  }

  if( goToVideos === true ) {
    setGoToVideos( false );
    history.push( "/videos/".concat( user.token ) );
    return null;
  }

  if( goToStatistic === true ) {
    setGoToStatistic( false );
    history.push( "/bike_statistic/".concat( user.token ) );
    return null;
  }


  if( location.pathname !== "/" && user ) {


    return <div className="appHeader">

      <div style={{
        "float": "left",
        "max-width": "212px",
        "padding-left": "10px"
      }}>
        <img style={{
          "max-width": "100%",
          "height": "auto"
        }} src="https://svebar.de/new/images/svebar_logo.png" alt="header"/>
      </div>
      <div style={{
        "float": "right",
        "height": "40px",
        "width": "100%"
      }}>
        <div id="dropIcon" onClick={( e ) => toggleDropdown( e )}
             className="container">
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>

          <div id="myDropdown" className="containerContent">
            {location.pathname !== "/overview/".concat( user.token ) &&
            <div onClick={() => setGoToOverview( true )}>Zur Startseite</div>}
            {location.pathname !== "/galleries/".concat( user.token ) &&
            <div onClick={() => setGoToGalleries( true )}>Zu den Bildergalerien</div>}
            {location.pathname !== "/videos/".concat( user.token ) &&
            <div onClick={() => setGoToVideos( true )}>Zu den Videos</div>}
            {location.pathname !== "/bike_statistic/".concat( user.token ) &&
            <div onClick={() => setGoToStatistic( true )}>Zur Radstatisik</div>}
            <div onClick={() => logout( user.token, history )}>Amelden</div>
          </div>
        </div>
      </div>

      <div style={{ "clear": "both" }}>
      </div>
    </div>
  }

  else {
    return <div className="backgroundImageStart">
      <div className="bigBackgroundImageWrapper">
        <img className="bigBackgroundImageLogo" src="https://svebar.de/new/images/svebar_logo.png" alt="header"/>
      </div>
    </div>;
  }
}

export default Header;