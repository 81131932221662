import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import he from 'he';
import ImageGallery from "react-image-gallery";
import useCheckToken from "./CheckToken";
import { GALLERY_CALLER } from "./store/dataReducer";
import Header from "./Header";
import RestClient from "./RestClient";


function useWindowSize() {

  const [windowSize, setWindowSize] = useState( {
    width: undefined,
    height: undefined,
  } );

  useEffect( () => {
    function handleResize() {
      setWindowSize( {
        width: window.innerWidth,
        height: window.innerHeight,
      } );
    }

    window.addEventListener( "resize", handleResize );

    handleResize();

    return () => window.removeEventListener( "resize", handleResize );
  }, [] );

  return windowSize;
}


const getImageGalleryInfos = ( token, id, setGalleryData, history ) => {

  const url = "https://svebar.de/new/space/gallery.php";
  const body = "token=" + token + "&id=" + id;

  const onSuccess = ( data ) => setGalleryData( data );

  RestClient( url, body, onSuccess, history );
}


const Gallery = () => {

  const [validUser, setValidUser] = useState( false );
  useCheckToken( validUser, setValidUser );

  const { id } = useParams();
  const history = useHistory();
  const size = useWindowSize();
  const user = useSelector( state => state.user );
  const galleryCaller = useSelector( state => state.data.galleryCaller );
  const [galleryData, setGalleryData] = useState( null );
  const [showImageGallery, setShowImageGallery] = useState( false );
  const [clickedImageIndex, setClickedImageIndex] = useState( 0 );
  const [scrollPosition, setScrollPosition] = useState( 0 );
  const breakpoints = [{
    "minSize": 1,
    "columns": 2,
    "width": "45%"
  }, {
    "minSize": 800,
    "columns": 3,
    "width": "30%"
  }, {
    "minSize": 1200,
    "columns": 4,
    "width": "24%"
  }];
  const [breakpoint, setBreakpoint] = useState( breakpoints[0] );
  const [goBackToCaller, setGoBackToCaller] = useState( false );
  const [bier, setBier] = useState( false );

  useEffect( () => {
    if( validUser && user && user.token !== null && (!galleryData || galleryData === null) ) {
      getImageGalleryInfos( user.token, id, setGalleryData, history );
    }
  }, [user, id, galleryData, validUser, history] );


  useEffect( () => {

    if( validUser ) {
      const bp = breakpoints.reverse().find( bp => {
        return size.width >= bp.minSize;
      } );

      if( size && size.width && showImageGallery === false && breakpoint.columns !== bp.columns ) {
        setBreakpoint( bp ? bp : breakpoints[breakpoints.length - 1] );
      }
    }
  }, [size, validUser, breakpoint.columns, breakpoints, showImageGallery] );


  if( goBackToCaller === true ) {
    setGoBackToCaller( false );

    if( galleryCaller === null || galleryCaller === GALLERY_CALLER.GALLERIES ) {
      history.push( "/galleries/".concat( user.token ) );
    }
    else if( galleryCaller === GALLERY_CALLER.OVERVIEW ) {
      history.push( "/overview/".concat( user.token ) );
    }
    return null;
  }


  if( !validUser ) return null;


  if( !galleryData || galleryData === null ) {
    return <div className="lds-dual-ring"></div>
  }

  const thumbnailImages = [];
  const images = [];

  galleryData.images.forEach( ( image, index ) => {
    thumbnailImages.push( {
      "number": index,
      "url": "https://svebar.de/space/images/".concat( galleryData.folderName ).concat( "/thumbnails/" ).concat( image )
    } )
    images.push( {
      "original": "https://svebar.de/space/images/"
        .concat( galleryData.folderName ).concat( "/" ).concat( image )
    } )

  } );


  const slice = ( arr, n ) => {

    const returnArr = [];
    for( let i = 0; i < n; i++ ) {
      const sliceArr = [];
      for( let e = i; e < arr.length; e = e + n ) {
        sliceArr.push( arr[e] );
      }
      returnArr.push( sliceArr );
    }
    return returnArr;
  }

  const slices = slice( thumbnailImages, breakpoint.columns );

  if( showImageGallery === false ) {
    return <>

      <Header user={user}/>

      <div className="font48DodgerBlue pointer marginLeft20"
           title="Zurück"
           onClick={() => setGoBackToCaller( true )}>&larr;</div>

      <div className="marginBottom80" style={{
        "margin-left": "auto",
        "margin-right": "auto",
        "width": "80%"
      }}>


        <div className=" font32DodgerBlue ">
          {he.decode( galleryData.description )}
          <br/>
          {galleryData.fileCount} Bilder
        </div>

        {slices.map( ( slice ) => {

          return (<div className="tile" style={{ "width": breakpoint.width }}>
            {slice.map( image => {
              return <img onClick={() => {
                setClickedImageIndex( image.number );
                setScrollPosition( window.pageYOffset );
                setBier( false );
                setShowImageGallery( true );
              }} src={image.url}
                          alt="thumbnail"/>
            } )}
          </div>)
        } )}
        <div style={{ "clear": "both" }}></div>
      </div>
    </>
  }

  if( showImageGallery === true ) {
    return <ImageGallery
      items={images}
      showThumbnails={false}
      showPlayButton={false}
      showNav={true}
      showFullscreenButton={true}
      infinite={false}
      disableSwipe={false}
      lazyLoad={true}
      startIndex={clickedImageIndex}
      ref={( comp ) => {
        if( !bier && comp && !comp.state.isFullscreen ) {
          setBier( true );
          comp.toggleFullScreen();
        }
      }}
      onScreenChange={( fullscreen ) => {
        if( fullscreen === false ) {
          setShowImageGallery( false )
          window.scrollTo( 0, scrollPosition )
        }
      }}
    />
  }

}

export default Gallery;


