import React from 'react';
import Start from "./Start";


const Logout = () => {
  return <Start logout={true}/>;
}

export default Logout;


