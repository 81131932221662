import React, { useEffect, useState } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_VIDEO_CALLER, SET_VIDEOS, VIDEO_CALLER } from "./store/dataReducer";
import { useHistory } from "react-router-dom";
import he from 'he';
import useCheckToken from "./CheckToken";
import Select from 'react-select'
import Header from "./Header";
import RestClient from "./RestClient";


const getVideos = ( token, dispatch, history ) => {

  const url = "https://svebar.de/new/space/videos.php";
  const body = "token=" + token;

  const onSuccess = ( data ) => dispatch( {
    type: SET_VIDEOS,
    data: data,
  } );

  RestClient( url, body, onSuccess, history );
}


const Videos = () => {

  const [validUser, setValidUser] = useState( false );
  useCheckToken( validUser, setValidUser );
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector( state => state.user );
  const videos = useSelector( state => state.data.videos );
  const [goToVideo, setGoToVideo] = useState( null );
  const [goToOverview, setGoToOverview] = useState( false );
  const [filter, setFilter] = useState( "" );

  const dropdownOptions = [{
    value: 'creationDateDesc',
    label: 'Neuste angelegt zuerst'
  }, {
    value: 'creationDateAsc',
    label: 'Älteste angelegt zuerst'
  }, {
    value: 'captureDateAsc',
    label: 'Neustes Aufnahmedatum zuerst'
  }, {
    value: 'captureDateDesc',
    label: 'Altestes Aufnahmedatum zuerst'
  }]
  const [selectedDropdownValue, setSelectedDropdownValue] = useState( dropdownOptions[0] );


  useEffect( () => {

    if( validUser && user && user.token !== null && (!videos || videos.length === 0) ) {
      getVideos( user.token, dispatch, history );
    }
  }, [user, dispatch, videos, validUser, history] );


  if( goToVideo !== null ) {
    dispatch( {
      type: SET_VIDEO_CALLER,
      caller: VIDEO_CALLER.VIDEOS
    } );
    setGoToVideo( null );
    history.push( "/video/".concat( goToVideo.id ).concat( "/" ).concat( user.token ) );
    return null;
  }


  if( goToOverview === true ) {
    setGoToOverview( false );
    history.push( "/overview/".concat( user.token ) );
    return null;
  }


  if( !validUser ) return null;

  if( !videos || videos.length === 0 ) {
    return <div className="lds-dual-ring"></div>;
  }

  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      minHeight: 42,
    }),
    valueContainer: base => ({
      ...base,
      height: 34,
      minHeight: 34
    }),
  };
  const filteredVideos = videos.filter( video => video.description.toUpperCase().includes( filter.toUpperCase() ) );
  const orderedAndFilteredVideos = filteredVideos.sort( function( video1, video2 ) {

    if( selectedDropdownValue.value === "creationDateDesc" ) {
      return new Date( video2.creationDate ) - new Date( video1.creationDate );
    }
    if( selectedDropdownValue.value === "creationDateAsc" ) {
      return new Date( video1.creationDate ) - new Date( video2.creationDate );
    }
    if( selectedDropdownValue.value === "captureDateAsc" ) {
      return new Date( video2.captureDate ) - new Date( video1.captureDate );
    }
    if( selectedDropdownValue.value === "captureDateDesc" ) {
      return new Date( video1.captureDate ) - new Date( video2.captureDate );
    }
    return 0;
  } );

  return <>

    <Header user={user}/>

    <div className="font48DodgerBlue pointer marginLeft20 floatLeft"
         title="Zurück zur Startseite"
         onClick={() => setGoToOverview( true )}>&larr;
    </div>

    <div className="sortGalleries">
      <input
        type="text"
        value={filter}
        placeholder="Suche"
        onChange={( e ) => {
          setFilter( e.target.value );
        }}
      />
    </div>
    <div className="sortGalleries">
      <Select options={dropdownOptions}
              isSearchable={false}
              value={selectedDropdownValue}
              onChange={( e ) => setSelectedDropdownValue( e )}
              styles={customStyles}
      />

    </div>
    <div style={{ clear: "both" }}></div>


    <div style={{
      "margin-left": "auto",
      "margin-right": "auto",
      "width": "70%"
    }} className="marginTop20 marginBottom80">

      {orderedAndFilteredVideos.map( video => {
        return <div onClick={() => setGoToVideo( video )} className="gallery-box">
          {he.decode( video.description )}
        </div>
      } )}
      <div style={{ clear: "both" }}></div>
    </div>
  </>
}

export default Videos;


