import React from 'react';


const NotFoundPage = () => {
  return <>
    Not found
  </>
}

export default NotFoundPage




