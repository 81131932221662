function RestClient( url, body, onSuccess, history ) {

  fetch( url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: body
  } )
    .then( response => {
      if( response.status === 200 && response.ok === true ) {
          response.json().then( data => {
            onSuccess( data );
          } )
      }
      else if( response.status === 401 && response.ok === false ) {
        history.push( "/invalid_session" );
      }
      else {
        history.push( "/error" );
      }
    } )
    .catch( ( error ) => {
      history.push( "/error" );
    } );
}

export default RestClient;