import { USER_LOGIN } from "./store/userReducer";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RestClient from "./RestClient";

const loadAndCheckToken = ( token, dispatch, history, setValidUser ) => {

  const url = "https://svebar.de/new/space/get_token_info.php";
  const body = "token=" + token;

  const onSuccess = ( data ) => {
    dispatch( {
      type: USER_LOGIN,
      user: data,
    } );
    setValidUser( true );
    return null;
  };

  RestClient( url, body, onSuccess, history );
}

const useCheckToken = ( validUser, setValidUser ) => {

  const history = useHistory();
  const { token } = useParams();
  const user = useSelector( state => state.user );
  const dispatch = useDispatch();


  if( validUser === true ) {
    console.log( "war schon hier, return null" )
    return null;
  }

  console.log( "token", token )
  if( !token ) {
    console.log( "no token in url" )
    history.push( "./../" );
    return null;
  }
  else if( user && user.name !== null && user.token !== null ) {
    console.log( "user in store" )
    if( user.token === token ) {
      console.log( "token match token in store" )
      setValidUser( true );
      return null;
    }
    else {
      console.log( "wrong token in url" )
      history.push( "./../" );
      return null;
    }
  }
  else if( !user || user.name === null || user.token === null ) {
    console.log( "no user in store - probably site reload" );
    return loadAndCheckToken( token, dispatch, history, setValidUser );
  }


}

export default useCheckToken;