import React from 'react';
import Start from "./Start";


const InvalidSesion = () => {
  return <Start invalidSession={true}/>;
}

export default InvalidSesion;


